import { useEffect, useState } from "react";
import React from "react";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { NavLink, NavLink as NavLinkResponsive } from 'react-router-dom';
import { Rectangle, SidebarContainer, StyledDividerResponsive, StyledNameUserParagraph, StyledNameUserResponsive, StyledNavLink } from './styles';
import { SidebarResponsive, StyledtitleText, StyledNameUser } from "./styles";
import { FiAlignLeft, FiX } from "react-icons/fi"
import { MenuButtonContainer } from "../../pages/settings/styles";
import { StyleSheetManager } from "styled-components";
import { useAuth } from '../../context/AuthContext';
import { Avatar, Box, Divider, Drawer, IconButton, List, ListItemButton, ListItemIcon, ListItemText, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { DescriptionOutlined, HelpOutlineOutlined, InsightsOutlined, Logout, Menu, SettingsOutlined } from "@mui/icons-material";
import { useDrawerContext } from "../../context/DrawerContext";
import { MenuItem, Menu as MuiMenu } from '@mui/material';
import HeaderManager from "../headerManager";
import { StyledContainerTutorial, StyledH5, StyledImg } from "../headerManager/styles";
import FileText from '../../assets/file-text-colored.svg'
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import ChangeCompanyDialog from "../headerManager/dialogModal/dialogModal";

const SidebarResponsiveComp: React.FC<any> = ({title}) =>{
    const [isOpen, setIsOpen] = useState(false);
    const toggleSidebar = () => setIsOpen(!isOpen);

    

    const iconPaths: any = {
        configuracao: require('../../assets/settings (1) 1.png'),
        sustentabilidade: require('../../assets/ic_twotone-speed.png'),
        calendario: require('../../assets/calendar (5) 1.png'),
        relatorio: require('../../assets/file-text (3) 1.png'),
        insight: require('../../assets/Insights.png'),
    };

   

    const theme = useTheme()

    const lgUp = useMediaQuery(theme.breakpoints.up('lg'))

    const {isDrawerOpen, toggleDrawerOpen} = useDrawerContext()

    const { user, SignOut } = useAuth()

        const getInitials = (name: string, surname: string) => {
        if (!name) return ''
        const firstInitial = name[0] ? name[0].toUpperCase() : '';
        const secondInitial = surname[0] ? surname[0].toUpperCase() : '';
        return firstInitial + secondInitial;
    }

    const [selectedIcon, setSelectedIcon] = useState<number | null>(() => {
        // Recuperar o estado inicial do localStorage
        const saved = localStorage.getItem('selectedIcon');
        return saved !== null ? Number(saved) : null;
    });


    useEffect(() => {
        // Salvar o estado atual no localStorage sempre que selectedIcon mudar
        localStorage.setItem('selectedIcon', String(selectedIcon));
    }, [selectedIcon]);
      
    useEffect(() => {
        
        if (window.location.pathname === '/admin/insights') {
          
          setSelectedIcon(0);
        }

        if (window.location.pathname === '/admin/relatorio') {
            
            setSelectedIcon(1);
        }

        if (window.location.pathname === '/admin/configuracoes') {
          
            setSelectedIcon(2);
        }
    }, []);  

    const handleLogoutButton = () => {
        SignOut('/admin/signup');
        setAnchorEl(null);
    };

    const [open, setOpen] = useState(false);
    const [showDialogModal, setShowDialogModal] = useState(false)
    
    const toggleDrawer = (newOpen: boolean) => () => {
        setOpen(newOpen);
    };

    const DrawerList = (
        <Box sx={{display: 'flex', flexDirection:'column', height:'100%',  justifyContent: 'space-between', width: 250}} role="presentation" onClick={toggleDrawer(false)}>
                    
            <List component='nav' sx={{display: 'flex', flexDirection:'column', justifyContent: 'center', gap: '1em', marginTop:'3em'}}>
                {/* <NavLink to="/admin/insights" key={3}  className='link'>
                    <ListItemButton>
                        <ListItemIcon>
                                <InsightsOutlined/> 
                        </ListItemIcon>
                        <ListItemText color="ContrastText">Insights</ListItemText>
                    </ListItemButton>
                </NavLink> */}

                <StyledNavLink to='/admin/insights' key={1}   >
                    <ListItemButton
                        sx={{
                            gap:' 1em',
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            backgroundColor: selectedIcon === 0 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                            borderRadius: '0 20px 20px 0',
                            '&:hover': {
                                backgroundColor: ' rgba(113, 237, 203, 0.40)',
                            },
                                
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                width: selectedIcon === 0 ? '8px' : '0',
                                backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                            }
                        }}
                        onClick={() => setSelectedIcon(0)}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 0 : 'auto',
                            justifyContent: 'center',
                        }}
                        >
                            <InsightsOutlined sx={{fontSize: 35, color:'#3C4043'}}/>
                        </ListItemIcon>
                        <ListItemText primary='Insights'  sx={{ display: open ? 'block' : 'none' }} />
                    </ListItemButton>
                </StyledNavLink> 


                <StyledNavLink to='/admin/relatorio' key={2}   >
                    <ListItemButton
                        sx={{
                            gap:' 1em',
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            backgroundColor: selectedIcon === 1 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                            borderRadius: '0 20px 20px 0',
                            position: 'relative',
                            '&:hover': {
                                backgroundColor: ' rgba(113, 237, 203, 0.40)',
                            },
                            '&::before': {
                            content: '""',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            width: selectedIcon === 1 ? '8px' : '0',
                            backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                            }
                        }}
                        onClick={() => setSelectedIcon(1)}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 0 : 'auto',
                            justifyContent: 'center',
                        }}
                        >
                            <DescriptionOutlined sx={{fontSize: 35, color:'#3C4043'}}/>
                        </ListItemIcon>
                        <ListItemText primary='Relatório Mensal'  sx={{ display: open ? 'block' : 'none' }} />
                    </ListItemButton>
                </StyledNavLink> 



                <StyledNavLink to='/admin/configuracoes' key={3}   >
                    <ListItemButton
                        sx={{
                            gap:' 1em',
                            justifyContent: open ? 'initial' : 'center',
                            px: 2.5,
                            backgroundColor: selectedIcon === 2 ? ' rgba(113, 237, 203, 0.40);' : 'transparent',
                            borderRadius: '0 20px 20px 0',
                            '&:hover': {
                                backgroundColor: ' rgba(113, 237, 203, 0.40)',
                            },
                            '&::before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                bottom: 0,
                                left: 0,
                                width: selectedIcon === 2 ? '8px' : '0',
                                backgroundImage: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)',

                            }
                        }}
                        onClick={() => setSelectedIcon(2)}
                    >
                        <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 0 : 'auto',
                            justifyContent: 'center',
                        }}
                        >
                            <SettingsOutlined sx={{fontSize: 35, color:'#3C4043'}}/>
                        </ListItemIcon>
                        <ListItemText primary='Configurações'  sx={{ display: open ? 'block' : 'none' }} />
                    </ListItemButton>
                </StyledNavLink> 

                
                
            </List>

            <List>
                        

                <ListItemButton
                    sx={{
                        gap: '1em',
                        justifyContent: open ? 'initial' : 'center',
                        px: 2.5,
                    }}
                >

                    <ListItemIcon sx={{
                            minWidth: 0,
                            mr: open ? 0 : 'auto',
                            justifyContent: open ? 'initial' : 'center',
                        }} >
                            <a href="https://zenboxsupport.zendesk.com/hc/pt-br" target="_blank" rel="noopener noreferrer">
                                <HelpOutlineOutlined  sx={{fontSize: 35, color:'#3C4043'}}/> 
                            </a>
                        
                    
                    </ListItemIcon>
                    <ListItemText primary='Central de Ajuda' sx={{ display: open ? 'block' : 'none' }} />
                </ListItemButton>

                

                        
            </List>     



        </Box>
    )

      
  

    const menuItem = [
        {
            path: "/admin/insights",
            name: "Insights",
            icon: iconPaths['insight']
        },
        {
            path: "/admin/calendario",
            name: "Calendário",
            icon: iconPaths['calendario']
        },
        {
            path: "/admin/sustentabilidade",
            name: "Sustentabilidade",
            icon: iconPaths['sustentabilidade']
        },
        {
            path: "/admin/relatorio",
            name: "Relatório",
            icon: iconPaths['relatorio']
        },
        {
            path: "/admin/configuracoes",
            name: "Configurações",
            icon: iconPaths['configuracao']
        },

    ] 

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const openProfile = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>

        
            <StyleSheetManager shouldForwardProp={(prop) => prop !== 'isOpen'}>
            <MenuButtonContainer>
                

                <Box padding='.5em' sx={{display: 'flex', alignItems: 'flex-start', gap:'.5em'}}>
                    <IconButton  onClick={toggleDrawer(true)}>
                        <Menu sx={{fontSize: 35, color:'#3C4043'}}/>
                    </IconButton> 


                    <div>
                        <StyledtitleText>{title}</StyledtitleText>

                        <StyledContainerTutorial style={{display:'flex', justifyContent:'flex-start'}}>
                        <StyledImg src={FileText} draggable ="false" alt="Logo" style={{width: '20px', height: '20px'}} />
                        <StyledH5><a href="https://zenboxsupport.zendesk.com/hc/pt-br" target="_blank" rel="noopener noreferrer">Tutorial</a></StyledH5>
                        </StyledContainerTutorial>
                
                    </div>
                
                </Box>
                <Drawer open={open} onClose={toggleDrawer(false)} variant={lgUp ? 'permanent' : 'temporary'}>
                    
                    {DrawerList}

                </Drawer>
                
                

                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '.5em',}}
                    >
                    <StyledNameUser>
                        <span style={{color:'#3C4043', fontSize: '16px', textAlign: 'right' , fontWeight: '700' }}>{user.user?.first_name} {user.user?.last_name}</span>
                        <span style={{color:'#3C4043', fontSize: '16px', textAlign: 'right' }}>{user?.user?.company_name}</span>
                    </StyledNameUser>
                    <IconButton
                        onClick={handleClick}
                        size="small" 
                    >
                        <Avatar sx={{background: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)', color:'#3C4043'}}>
                        {getInitials(user.user?.first_name, user.user?.last_name)}
                        </Avatar>
                    </IconButton>
                    
                    
                    <MuiMenu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={openProfile}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                          elevation: 0,
                          sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                              width: 32,
                              height: 32,
                              ml: -0.5,
                              mr: 1,
                            },
                            '&::before': {
                              content: '""',
                              display: 'block',
                              position: 'absolute',
                              top: 0,
                              right: 14,
                              width: 10,
                              height: 10,
                              bgcolor: 'background.paper',
                              transform: 'translateY(-50%) rotate(45deg)',
                              zIndex: 0,
                            },
                          },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    
                    >
                        <MenuItem>
                            <StyledNameUserResponsive>
                                <Tooltip placement="top"  arrow title={`${user?.user?.first_name} ${user?.user?.last_name }` } >
                                    <StyledNameUserParagraph style={{marginBottom:'0', color:'#3C4043', fontSize: '16px',  fontWeight: '700' }}>{user.user?.first_name} {user.user?.last_name} </StyledNameUserParagraph>
                                </Tooltip>
                                <p style={{marginBottom:'0', color:'#3C4043', fontSize: '16px', }}>{user?.user?.company_name}</p>
                            </StyledNameUserResponsive>
                        </MenuItem>

                        <StyledDividerResponsive>
                            <Divider sx={{ 
                                borderColor: '#3C4043', 
                            }}/>    
                        </StyledDividerResponsive>
                       

                        {user?.user?.role === 1 ? (
                            <MenuItem onClick={() => setShowDialogModal(true)}>
                                <ListItemIcon>
                                <DomainRoundedIcon fontSize='small'/>
                                </ListItemIcon>
                                Trocar de organização
                            </MenuItem>
                        ) : (
                            <div></div>
                        )}

                        <MenuItem onClick={handleLogoutButton}>
                            <ListItemIcon>
                            <Logout fontSize="small" />
                            </ListItemIcon>
                            Sair
                        </MenuItem>
                    </MuiMenu>
                </div>
               
                <ChangeCompanyDialog show={showDialogModal} onClose={() => setShowDialogModal(false)} />
            </MenuButtonContainer>
                

            </StyleSheetManager>

        </>
      
    );


}

export default SidebarResponsiveComp;
