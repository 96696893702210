
import { Height } from '@mui/icons-material';
import Chart from 'react-apexcharts';

type ICard = {

    typeKey?: any
    data: any
    height: number
    lineSize: string,
    positionLine: string,
}
type IChart = {
    quantities: number[],
    months: string[],
    height: number

}
const ChartDash = ({ quantities, height, months }: IChart) => {
    let series_name = 'IFOZ'
    let data = quantities

    const series = [{
        name: series_name,
        data: data,
        color: '#2B81FF'
    }];
    return (
        <Chart 
        options={{
            chart: {
                toolbar: {
                    show: false
                },
                animations: {
                    enabled: true,
                    easing: 'easeinout',
                    speed: 800,
                    animateGradually: {
                        enabled: true,
                        delay: 150
                    },
                    dynamicAnimation: {
                        enabled: true,
                        speed: 350
                    }
                },
                zoom: {
                    enabled: false,
                },




            },
            dataLabels: {
                enabled: true,
                offsetX: 0,
                offsetY: -8,
                formatter: (value: number | any) => {
                    return (value || value == 0) ? `${value}%` : ``; // Isso adicionará um sinal de porcentagem ao final do valor
                },
                background: {
                    enabled: false,
                    foreColor: '#fff',
                    padding: 4,
                    borderRadius: 2,
                    borderWidth: 1,
                    borderColor: '#fff',
                    opacity: 0.9,

                },
                style: {
                    fontSize: '12px',
                    colors: ['#3C4043'],
                },


            },
            stroke: {
                curve: 'smooth',
                colors: ['#2B81FF']
            },
            xaxis: {
                categories: months,
                tickPlacement: 'between',
                labels: {
                    rotate: -45, // inclina as labels em -45 graus
                    style: {
                        colors: ['#878B8E', '#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E','#878B8E'],
                    }
                }
            },
            yaxis: {
                opposite: false,
                tickAmount: 4,
                min: 0,
                max: 100,
                labels: {
                    style: {
                        colors: ['#3C4043']
                    },
                    formatter: (value: number | any) => {
                        return (value || value == 0) ? `${value}%` : `- -`; // Isso adicionará um sinal de porcentagem ao final do valor
                    }
                }
            },
            fill: {
                type: 'gradient',
                colors: ['#D9D9D9']
            },
            annotations: {
                yaxis: [
                    {
                      y: 70,
                      strokeDashArray: 0,
                      borderColor: '#8fff1d',
                      borderWidth: 4,
                      label: {
                        borderColor: '#8fff1d',
                        style: {
                          color: '#3C4043',
                          background: '#8fff1d',
                          fontSize: '12px',
                        },
                        text: 'Bom',
                      }
                    }
                  ],

            },

            markers: {
                size: 6,
                colors: ['#2491FF'],
                strokeColors: '#CACBDA',
                strokeWidth: 2,
                hover: {
                    size: 7, // Tamanho dos marcadores quando o mouse passa sobre eles
                }
            },
           
            tooltip: {
                enabled: true,
                x: {
                    show: true
                },
                y: {
                    formatter: (value: number | any) => {
                        return (value || value == 0) ? `${value}%` : `Dados insuficientes`; // Isso adicionará um sinal de porcentagem ao final do valor
                    }
                },
            },
            grid: {
                show: true,

                xaxis: {
                    lines: {
                        show: true
                    }
                },
                yaxis: {
                    lines: {
                        show: true,
                    }
                },
                row: {
                    colors: ['rgba(241, 241, 241, 1)'],
                    opacity: 0.5
                },
                column: {
                    colors: ['rgba(241, 241, 241, 1)'],
                    opacity: 0.5
                },
            }



        }} series={series} type="line" height={height} />

    )

};

const DashboardChart = ({ typeKey, data, height, lineSize, positionLine }: ICard) => {
    const monthCurt:any= {
        'Janeiro': 'Jan',
        'Fevereiro': 'Fev',
        'Março': 'Mar',
        'Abril': 'Abr',
        'Maio': 'Mai',
        'Junho': 'Jun',
        'Julho': 'Jul',
        'Agosto': 'Ago',
        'Setembro': 'Set',
        'Outubro': 'Out',
        'Novembro': 'Nov',
        'Dezembro': 'Dez'
    };
    const abbreviatedData = data.map((item:any) => ({
        ...item,
        month: monthCurt[item.month] 
    }));
    const monthMapping: any = {
        'Jan': 1,
        'Fev': 2,
        'Mar': 3,
        'Abr': 4,
        'Mai': 5,
        'Jun': 6,
        'Jul': 7,
        'Ago': 8,
        'Set': 9,
        'Out': 10,
        'Nov': 11,
        'Dez': 12
    };
    abbreviatedData.sort((a: any, b: any) => monthMapping[a.month] - monthMapping[b.month]);
    const quantities = abbreviatedData.map((item:any) => {
        return item.result == null ? 0 : +item.result.toFixed(2);
    });


      
    const months = abbreviatedData.map((item: { month: any; }) => item.month);

    // console.log(data.map((item: any) => monthCurt[item.month]))
    // console.log(data)
    return (
        <div style={{ position: "relative" }}>
            <div style={{
                width: '5px',
                height:`${lineSize}`,
                position: 'absolute',
                top: `${positionLine}`,
                left: '50px',
                right: 0,
                bottom: 0,
                background: 'linear-gradient(180deg, #00920F 0%, #00FF19 32%, #ECFF20 47.19%, #F1C118 68%, #F00 100%)'
               }}> 
            </div>
            <ChartDash height={height} key={typeKey} months={data.map((item: any) => monthCurt[item.month] + "/" + item.year.toString().slice(-2)).reverse()} quantities={data.map((item: any) => (item.result ? Number(item.result.toFixed(1)) : item.result)).reverse()}  />
        </div>
    )


}

export default DashboardChart

