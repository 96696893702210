import { FilterList } from '@mui/icons-material'
import EventIcon from '@mui/icons-material/Event'
import FilterListIcon from '@mui/icons-material/FilterList'
import { FormControl, InputLabel, MenuItem, OutlinedInput, Select, SelectChangeEvent } from '@mui/material'
import debounce from 'debounce-promise'
import { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Spinner } from 'react-bootstrap'
import { GroupBase, ValueContainerProps, components } from 'react-select'
import { StyledCardBody, StyledCardBodyOverflow, StyledCardHeader } from '../../components/dashboard/attentionPoints/styles'
import DashboardChart from '../../components/dashboard/chart'
import TableComponent from '../../components/dashboard/table'
import HeaderManager from '../../components/headerManager'
import SidebarResponsiveComp from '../../components/sideBar/siderbar-responsive'
import { useAuth } from '../../context/AuthContext'
import { useToast } from '../../context/Toast'
import questions from '../../data/questions.json'
import { LineChartSeriesInterface } from '../../interfaces/chartSeries.interface'
import { FormattedDimensionResults } from '../../interfaces/dimension.interface'
import { getGroupByCompany } from '../../services/group/group'
import { getDimensionHistoricByParameters, getENHistoric, getEPHistoric, getIEPHistoric, getIFCZHistoric, getIFOZGroupCompany, getIFOZHistoric, getIFOZSexCompany, getIPAGroupCompany, getIPAHistoric, getIPASexCompany, getREAHistoric, getREHistoric, getSIHistoric, getVITHistoric } from '../../services/statistics/insights'
import { CustomNav } from '../settings/styles'
import { ContainerDashboard, ContainerFilterPeriod, ContainerFilters, ContainerSelectFilters } from './styles'
import { DimensionID } from '../../enums/dimension'


type FilteredDataModel = {
    filteredIap: number | null,
    filteredIfoz: number | null,
    filteredIep: number | null,
    filteredIfcz: number | null,
    filteredEpDimension: FormattedDimensionResults | null,
    filteredEngDimension: FormattedDimensionResults | null,
    filteredVitDimension: FormattedDimensionResults | null,
    filteredRelDimension: FormattedDimensionResults | null,
    filteredSigDimension: FormattedDimensionResults | null,
    filteredReaDimension: FormattedDimensionResults | null
}

type GeneralDataModel = {
    generalIap: number | null,
    generalIfoz: number | null,
    generalIep: number | null,
    generalIfcz: number | null,
    generalEpDimension: FormattedDimensionResults | null,
    generalEngDimension: FormattedDimensionResults | null,
    generalVitDimension: FormattedDimensionResults | null,
    generalRelDimension: FormattedDimensionResults | null,
    generalSigDimension: FormattedDimensionResults | null,
    generalReaDimension: FormattedDimensionResults | null
}


const Insights = () => {

    const { user, SignOut } = useAuth()
    const { showToast } = useToast()
    const [loading, setLoading] = useState(true)

    const [loadingIndicators, setLoadingIndicators] = useState(true)

    const [loadingEpHistoric, setLoadingEpHistoric] = useState(true)
    const [loadingEngHistoric, setLoadingEngHistoric] = useState(true)
    const [loadingRelHistoric, setLoadingRelHistoric] = useState(true)
    const [loadingSigHistoric, setLoadingSigHistoric] = useState(true)
    const [loadingReaHistoric, setLoadingReaHistoric] = useState(true)
    const [loadingVitHistoric, setLoadingVitHistoric] = useState(true)

    const [pageData, setPageData] = useState({
        epHistoric: [],
        ipaHistoric: [],
        iepHistoric: [],
        ifczHistoric: [],
        ifozHistoric: [],
        reaHistoric: [],
        engHistoric: [],
        relHistoric: [],
        sigHistoric: [],
        vitHistoric: [],
        points: null
    })
    const [filteredData, setFilteredData] = useState({
        filteredIap: null,
        filteredIfoz: null,
        filteredIep: null,
        filteredIfcz: null,
        filteredEpDimension: null,
        filteredEngDimension: null,
        filteredVitDimension: null,
        filteredRelDimension: null,
        filteredSigDimension: null,
        filteredReaDimension: null
    } as FilteredDataModel)

    const [generalData, setGeneralData] = useState({
        generalIap: null,
        generalIfoz: null,
        generalIep: null,
        generalIfcz: null,
        generalEpDimension: null,
        generalEngDimension: null,
        generalVitDimension: null,
        generalRelDimension: null,
        generalSigDimension: null,
        generalReaDimension: null
    } as GeneralDataModel)


    const [genderSeries, setGenderSeries] = useState([] as LineChartSeriesInterface[])
    const [ageRangesSeries, setAgeRangesSeries] = useState([] as LineChartSeriesInterface[])
    const [groupsSeries, setGroupsSeries] = useState([] as LineChartSeriesInterface[])
    const [representationFilterHasChanged, setRepresentationFilterHasChanged] = useState(false)
    const [groupFilterHasChanged, setGroupFilterHasChanged] = useState(false)
    const [groupId, setGroupId] = useState(null)
    const [sexId, setSexId] = useState(null)
    const [dateValue, setDateValue] = useState<any>('');
    const [dimensionsByFilters, setDimensionsByFilters] = useState([] as LineChartSeriesInterface[])


    const [selectGroupOptions, setSelectGroupOptions] = useState<object[]>([]);
    // const loadGroupInputOptions = async (term: string) => {
    //     return new Promise(async (resolve, reject) => {
    //         try {
    //             let options: any = []
    //             const company = user.user?.company_id
    //             if (!company) throw new Error("company_not_found")
    //             let response: any = await getGroupByCompany({ company_id: company }, 1)

    //             if (!response || response?.data?.length <= 0) return resolve(options)

    //             response?.data?.forEach((element: any) => {
    //                 options.push({
    //                     label: element?.name,
    //                     value: element.id
    //                 })
    //             })

    //             return resolve(options)
    //         } catch (e) {
    //             console.error(e)
    //             return reject([])
    //         }
    //     })
    // }


    const loadGroupInputOptions = async (term?: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                let options: any = []
                const company = user.user?.company_id

                if (!company)
                    throw new Error("company_not_found")

                let companyGroupList: any = await getGroupByCompany({ company_id: company }, 1) // TODO: Implementar lógica de percorrer todas as páginas de grupos

                if (!companyGroupList || companyGroupList?.data?.length <= 0)
                    return resolve(options)

                companyGroupList?.data?.forEach((element: any) => {
                    options.push({
                        label: element?.name,
                        value: element.id
                    })
                })

                options = options.sort((a: any, b: any) => {
                    if (a.label < b.label) {
                        return -1;
                    }
                    if (a.label > b.label) {
                        return 1;
                    }
                    return 0;
                });

                return resolve(options)

            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }


    const clearFilteredData = () => {
        setFilteredData({
            filteredIap: null,
            filteredIfoz: null,
            filteredIep: null,
            filteredIfcz: null,
            filteredEpDimension: null,
            filteredEngDimension: null,
            filteredVitDimension: null,
            filteredRelDimension: null,
            filteredSigDimension: null,
            filteredReaDimension: null
        });
    }

    const loadFilteredData = async (year?: string, month?: string) => {
        let representationsToFilter = representationSelectedOptions;
        let groupsToFilter = selectedGroupOptions;
        let thereIsSomeFilter = (representationsToFilter?.length > 0) || (groupsToFilter?.length > 0)

        // Caso não haja nenhum filtro por representatividade nem por agrupamento, é solicitado o carregamento padrão
        if (!thereIsSomeFilter) {
            setDimensionsByFilters([]);
            setSelectedGroups([]);
            clearFilteredData();
            loadData()
            return;
        }


        // Aqui rodamos quando há pelo menos um filtro selecionado, seja ele por representatividade ou por agrupamento
        try {


        } catch (error: any) {
            setLoading(false)

            if (error?.response?.status === 401) {
                showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                setTimeout( () => {
                    showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                    setTimeout( () => {                                
                        SignOut('/admin/signup');
                    }, 5000);
                }, 2500);

            } else if (error?.response?.status === 403) {
                showToast('Ops! Parece que você não tem permissão para isso :(\n\nVerifique se você está utilizando a conta correta. Caso precise de mais informações, entre em contato com nosso suporte clicando no botão de "Ajuda" na parte inferior da sua tela.', 'error', 30000)

            } else if (error?.response?.data?.error) {
                switch (error.response.data.error) {
                    case "Erro ao validar o token - zenbox_backend":
                        showToast('Sua sessão expirou!\n\nPor questões de segurança, o seu acesso expira depois de certo tempo.', 'info', 7500)
                        setTimeout( () => {
                            showToast('Você será redirecionado para a tela de login em 5 segundos', 'info', 5000)
                            setTimeout( () => {                                
                                SignOut('/admin/signup');
                            }, 5000);
                        }, 2500);
                        break;

                    default:
                        showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000);
                        throw error;
                }
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                throw error
            }
        }
    };

    // const debounceOptionsGroup: any = debounce(loadGroupInputOptions, 600)

    const loadSexInputOptions = async (term: string) => {
        return new Promise(async (resolve, reject) => {
            try {
                const options = [
                    { value: 1, label: 'Masculino' },
                    { value: 2, label: 'Feminino' },
                    { value: 3, label: 'Outros' }
                ];
                return resolve(options)
            } catch (e) {
                console.error(e)
                return reject([])
            }
        })
    }

    const debounceOptionsSex: any = debounce(loadSexInputOptions, 600)

    function enrichData(data: any) {
        const questionMap = questions.reduce((map: any, item) => {
            map[item.id.toString()] = item;
            return map;
        }, {});

        ['minors', 'bigger'].forEach(category => {
            data[category] = data[category].map((item: any) => {
                const questionDetails = questionMap[item.question];
                if (questionDetails) {
                    return {
                        ...item,
                        dimension: questionDetails.dimension,
                        fullQuestion: questionDetails.question
                    };
                }
                return item;
            });
        });

        return data;
    }
    const loadData = async (year?: string, month?: string) => {
        try {
            setLoading(true)
            setLoadingEpHistoric(true);
            setLoadingEngHistoric(true);
            setLoadingRelHistoric(true);
            setLoadingSigHistoric(true);
            setLoadingReaHistoric(true);
            setLoadingVitHistoric(true);
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear().toString();
            const currentMonth = (currentDate.getMonth() + (currentDate.getDate() < 20 ? 0 : 1)).toString().padStart(2, '0');

            const yearToUse = year || currentYear;
            const monthToUse = month || currentMonth;

            const company = user.user?.company_id
            if (!company)
                throw new Error("company_not_found")

            const companyIFOZHistoric = await getIFOZHistoric(company, monthToUse, yearToUse);

            setPageData({
                ...pageData,
                ifozHistoric: companyIFOZHistoric && companyIFOZHistoric.length > 0 ? companyIFOZHistoric : [],
            });

            setLoading(false);

            const [
                companyIPAHistoric,
                companyIEPHistoric,
                companyIFCZHistoric
            ] = await Promise.all([
                getIPAHistoric(company, monthToUse, yearToUse),
                getIEPHistoric(company, monthToUse, yearToUse),
                getIFCZHistoric(company, monthToUse, yearToUse)
            ]);

            setPageData({
                ...pageData,
                ifozHistoric: companyIFOZHistoric && companyIFOZHistoric.length > 0 ? companyIFOZHistoric : [],
                ipaHistoric: companyIPAHistoric && companyIPAHistoric.length > 0 ? companyIPAHistoric : [],
                iepHistoric: companyIEPHistoric && companyIEPHistoric.length > 0 ? companyIEPHistoric : [],
                ifczHistoric: companyIFCZHistoric && companyIFCZHistoric.length > 0 ? companyIFCZHistoric : [],
            });

            setLoadingIndicators(false);

            let companyEPHistoric, companyEN, companyRE, companySIHistoric, companyREAHistoric, companyVITHistoric, companyPoints;

            try {
                companyEPHistoric = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.POSITIVE_EMOTIONS);
                companyEN = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.ENGAGEMENT);
                companyRE = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.RELATIONSHIP);
                companySIHistoric = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.MEANING);
                companyREAHistoric = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.ACCOMPLISHMENT);
                companyVITHistoric = await getDimensionHistoricByParameters(company, monthToUse, yearToUse, DimensionID.VITALITY);
            } catch (error) {
                console.error("Erro ao obter os dados:", error);
            }
            
            setLoadingEpHistoric(false);
            setLoadingEngHistoric(false);
            setLoadingRelHistoric(false);
            setLoadingSigHistoric(false);
            setLoadingReaHistoric(false);
            setLoadingVitHistoric(false);

            setPageData({
                ...pageData,
                ifozHistoric: companyIFOZHistoric && companyIFOZHistoric.length > 0 ? companyIFOZHistoric : [],
                ipaHistoric: companyIPAHistoric && companyIPAHistoric.length > 0 ? companyIPAHistoric : [],
                iepHistoric: companyIEPHistoric && companyIEPHistoric.length > 0 ? companyIEPHistoric : [],
                ifczHistoric: companyIFCZHistoric && companyIFCZHistoric.length > 0 ? companyIFCZHistoric : [],
                epHistoric: companyEPHistoric && companyEPHistoric.length > 0 ? companyEPHistoric : [],
                engHistoric: companyEN && companyEN.length > 0 ? companyEN : [],
                reaHistoric: companyREAHistoric && companyREAHistoric.length > 0 ? companyREAHistoric : [],
                relHistoric: companyRE && companyRE.length > 0 ? companyRE : [],
                sigHistoric: companySIHistoric && companySIHistoric.length > 0 ? companySIHistoric : [],
                vitHistoric: companyVITHistoric && companyVITHistoric.length > 0 ? companyVITHistoric : [],
                // points: companyPoints ? enrichData(companyPoints) : null
                points: null
            });
        } catch (error: any) {
            setLoading(false);
            setLoadingIndicators(false);
            setLoadingEpHistoric(false);
            setLoadingEngHistoric(false);
            setLoadingRelHistoric(false);
            setLoadingSigHistoric(false);
            setLoadingReaHistoric(false);
            setLoadingVitHistoric(false);
            throw error
        }
    }



    useEffect(() => {
        let currentDate = new Date();
        if (currentDate.getDate() < 20)
            currentDate.setMonth(currentDate.getMonth() - 1)
        const formattedDate = currentDate.toISOString().slice(0, 7);
        setDateValue(formattedDate);

        const fetchData = async () => {
            try {
                // Carrega as opções dentro do Select de Filtro por Grupos
                let groupInputOptions = await loadGroupInputOptions() as object[];
                setSelectGroupOptions(groupInputOptions);

                // Depois que as opções do grupo forem carregadas, você pode chamar a função de carga de dados
                loadData();
            } catch (error: any) {
                throw error
            }
        };
        fetchData();
    }, []);

    const searchByGroup = async (group_id: any, page: number) => {

        if (!group_id) {
            setGroupId(group_id)
            loadData()
            return
        }

        setGroupId(group_id)

        try {
            const currentDate = new Date();
            const currentYear = currentDate.getFullYear().toString();
            const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const [year, month] = dateValue.split('-')
            const yearToUse = year || currentYear;
            const monthToUse = month || currentMonth;

            setLoading(true)
            const company = user.user?.company_id
            let companyGruopIpa = 0
            let companyGroupIfoz = null
            if (!company) throw new Error("company_not_found")
            try {
                companyGruopIpa = await getIPAGroupCompany(company, group_id.value, monthToUse, yearToUse)
            } catch (error) {
                console.log(error)
                companyGruopIpa = 0
            }


            try {
                companyGroupIfoz = await getIFOZGroupCompany(company, group_id.value, monthToUse, yearToUse)
            } catch (error) {
                console.log(error)
                companyGroupIfoz = 0
            }



            setPageData({
                ...pageData,
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }

    }

    const searchByDate = async (value: any) => {
        setDateValue(value); //dps arrumar quando retornar erro 404 em loadData
        if (!value) {
            loadData()
        }
        else {
            const [year, month] = value.split('-');
            loadData(year, month)
        }
    }


    const searchBySex = async (sex_id: any, page: number) => {
        if (!sex_id) {
            setSexId(sex_id)
            loadData()
            return
        }

        setSexId(sex_id)
        try {

            const currentDate = new Date();
            const currentYear = currentDate.getFullYear().toString();
            const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');
            const [year, month] = dateValue.split('-')
            const yearToUse = year || currentYear;
            const monthToUse = month || currentMonth;

            setLoading(true)
            const company = user.user?.company_id
            let companySexIpa = 0
            let companySexIfoz = null
            if (!company) throw new Error("company_not_found")
            try {
                companySexIpa = await getIPASexCompany(company, sex_id.value, monthToUse, yearToUse)
            } catch (error) {
                console.log(error)
                companySexIpa = 0
            }


            try {
                companySexIfoz = await getIFOZSexCompany(company, sex_id.value, monthToUse, yearToUse)
            } catch (error) {
                console.log(error)
                companySexIfoz = 0
            }



            setPageData({
                ...pageData,
            });
            setLoading(false)
        } catch (error) {
            setLoading(false)
            console.log(error)
        }



    }


    const ValueContainer = ({ children, ...props }: ValueContainerProps<unknown, boolean, GroupBase<unknown>>) => (
        <components.ValueContainer {...props}>
            <EventIcon /> {/* Use o ícone de calendário aqui */}
            {children}
        </components.ValueContainer>
    );

    const DropdownIndicator = (props: any) => {
        return (
            <components.DropdownIndicator {...props}>
                <FilterList />
            </components.DropdownIndicator>
        );
    };


    // FILTRO DE REPRESENTATIVIDADE
    // Variável que vai armazenar em uma array de strings as opções que irão ser exibidas dentro do select de representatividade
    const [representationSelectedOptions, setRepresentationSelectedOptions] = useState<string[]>([]);

    // Handler que monitora qualquer alteração no valor do select de representatividade
    const handleChangeRepresentation = (selectedOption: any) => {
        const { target: { value } } = selectedOption;

        setRepresentationSelectedOptions(typeof value === 'string' ? value.split(',') : value);
        setRepresentationFilterHasChanged(true);
    };

    const handleOpenRepresentationSelect = (event: any) => {
        // Retira o foco da primeira opção do select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }
        }, 0);
    }

    // Handler que monitora quando o usuário fecha o select de representatividade após tê-lo aberto
    const handleCloseRepresentationSelect = (event: any) => {
        // Retira o foco do campo de select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }
        }, 0);
        if (representationFilterHasChanged) {
            loadFilteredData();
            setRepresentationFilterHasChanged(false);
        }
    }
    // FILTRO DE REPRESENTATIVIDADE



    // FILTRO DE GRUPO

    const [selectedGroupOptions, setSelectedGroups] = useState<string[]>([]);
    const handleChangeGroup = (selectedGroupInputOptions: any) => {
        const { target: { value } } = selectedGroupInputOptions;

        setSelectedGroups(typeof value === 'string' ? value.split(',') : value)
        setGroupFilterHasChanged(true);
    };

    const handleOpenGroupSelect = (event: any) => {
        // Retira o foco da primeira opção do select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }
        }, 0);
    }

    const handleCloseGroupSelect = (event: any) => {
        // Retira o foco do campo de select.
        // Precisa o timeout para garantir que as alterações no DOM sejam feitas pelo componente antes, e então retirar o foco do elemento certo na sequência
        setTimeout(() => {
            if (document?.activeElement && typeof (document.activeElement as HTMLElement).blur === 'function') {
                (document.activeElement as HTMLElement).blur();
            }
        }, 0);

        if (groupFilterHasChanged) {
            loadFilteredData();
            setGroupFilterHasChanged(false);
        }
    }
    // FILTRO DE GRUPO


    //FILTRO POR PERIODO

    const [periodFilterHasChanged, setPeriodFilterHasChanged] = useState(false)
    const [periodFilterValue, setPeriodFilterValue] = useState<string>('');

    const handleChangePeriod = (event: SelectChangeEvent<string>) => {
        setPeriodFilterValue(event.target.value as string);
        setPeriodFilterHasChanged(true);
    };

    const dataPeriodCurrentYear: any = [
        // { value: 'AnoAtual', label: 'Ano atual' },
        // { value: 'PrimerioTrimestre2024', label: '1º Trimestre de 2024' },
        // { value: 'SegundoTrimestre2024', label: '2º Trimestre de 2024' },
        // { value: 'PrimeiroSemestre', label: '1º Semestre desse ano' },
    ];

    const dataPeriodLastYear: any = [
        // { value: 'AnoPassado', label: 'Ano passado' },
        // { value: 'PrimeiroTrimestre2023', label: '1º Trimestre de 2023' },
        // { value: 'SegundoTrimestreDe2023', label: '2º Trimestre de 2023' },
        // { value: 'TerceiroTrimestreDe2023', label: '3º Trimestre de 2023' },
        // { value: 'QuartoTrimestreDe2023', label: '4º Trimestre de 2023' },
        // { value: 'PrimeiroSemestreDe2023', label: '1º Semestre de 2023' },
        // { value: 'SegundoSemestreDe2023', label: '2º Semestre de 2023' },
    ];
    const dataPeriodCustom = [
        { value: 'UltimosDozeMesesAContarDoMesAtual', label: 'Últimos 12 meses' },
    ];

    const defaultPeriod = dataPeriodCustom.find(item => item.value === 'Últimos 12 meses a contar do mês atual')

    //FILTRO POR PERIODO


    return (
        <>
            <SidebarResponsiveComp title='Insights' />
            <HeaderManager title='Insights' />
            <CustomNav className="mt-1" style={{ margin: '0', border: 'none' }} >
            </CustomNav>

            <Container fluid className='d-flex flex-column' >

                <ContainerSelectFilters>

                    <ContainerFilterPeriod style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>

                        <FormControl sx={{ width: '100%', background: '#FFF', minHeight: '52.16px', borderRadius: '6px' }}>
                            <InputLabel id="period-filter">Filtrar por período</InputLabel>
                            <Select
                                labelId="period-filter"
                                id="period-filter"
                                value={'UltimosDozeMesesAContarDoMesAtual'}
                                defaultValue='UltimosDozeMesesAContarDoMesAtual'
                                onChange={handleChangePeriod}
                                IconComponent={FilterListIcon}
                                input={<OutlinedInput label="Filtrar por período"
                                    color='primary'
                                    sx={{
                                        color: '#3C4043', //cor do texto dentro do input
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor:'#c9c9c9',
                                            // borderColor: periodFilterValue ? '#c9c9c9' : 'transparent', // Cor da borda
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9',

                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9', // Cor da borda ao passar o mouse
                                        },
                                        '.MuiSelect-iconOpen': {
                                            transform: 'none !important',
                                        },

                                        '@media (max-width:601px)': {
                                            width: '100%',
                                            fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                        },
                                    }}

                                />}

                            >

                                {/* <ListSubheader>Ano atual</ListSubheader>
                                {dataPeriodCurrentYear.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}

                                <ListSubheader>Ano passado</ListSubheader>
                                {dataPeriodLastYear.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))} */}

                                {/* <ListSubheader>Período relátivo</ListSubheader> */}
                                {dataPeriodCustom.map((item, index) => (
                                    <MenuItem key={index} value={item.value}>
                                        {item.label}
                                    </MenuItem>
                                ))}

                            </Select>
                        </FormControl>





                    </ContainerFilterPeriod>



                    <ContainerFilters>
                        {/* <StyledAsyncSelectFilter
                            placeholder="Filtrar por representatividade"
                            name='sex'
                            defaultOptions
                            isClearable
                            loadOptions={debounceOptionsSex}
                            styles={colourStylesNormalSelect}
                            isSearchable={false}
                            components={{ DropdownIndicator }}
                            value={sexId}
                            noOptionsMessage={() => 'Não há dados de segmentação'}
                            onChange={(e: any) => {
                                searchBySex(e, 1)
                            }}
                        /> */}

                        {/* <FormControl sx={{ width: '100%', background: '#FFF', minHeight: '52.16px', borderRadius: '6px' }}>
                            <InputLabel id="multiple-select">Filtrar por representatividade</InputLabel>
                            <Select

                                labelId="multiple-select"
                                id="multiple-select"
                                multiple
                                value={representationSelectedOptions}
                                onChange={handleChangeRepresentation}
                                onOpen={handleOpenRepresentationSelect}
                                IconComponent={FilterListIcon}
                                onClose={handleCloseRepresentationSelect}
                                input={<OutlinedInput label="Filtrar por representatividade"
                                    color='primary'
                                    sx={{
                                        color: '#3C4043', //cor do texto dentro do input
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: representationSelectedOptions.length > 0 ? '#c9c9c9' : 'transparent', // Cor da borda
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9',

                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9', // Cor da borda ao passar o mouse
                                        },
                                        '.MuiSelect-iconOpen': {
                                            transform: 'none !important',
                                        },

                                        '@media (max-width:601px)': {
                                            width: '100%',
                                            fontSize: '14px', // Reduz a largura para 70% em telas maiores
                                        },
                                    }}

                                />}
                                renderValue={(selected) => selected.join(', ')}
                            >
                                <ListSubheader>Por Gênero</ListSubheader>
                                {representationGenderOptions.map((name: string) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={representationSelectedOptions.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}

                                <ListSubheader>Por Faixa Etária</ListSubheader>
                                {representationAgeRangeOptions.map((name: string) => (
                                    <MenuItem key={name} value={name}>
                                        <Checkbox checked={representationSelectedOptions.indexOf(name) > -1} />
                                        <ListItemText primary={name} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>

                        <FormControl sx={{ width: '100%', background: '#FFF', minHeight: '52.16px', borderRadius: '6px' }}>
                            <InputLabel id="multiple-select-group">Filtrar por grupo</InputLabel>
                            <Select
                                labelId="multiple-select-group"
                                id="multiple-select-group"
                                multiple
                                value={selectedGroupOptions}
                                onChange={handleChangeGroup}
                                onOpen={handleOpenGroupSelect}
                                IconComponent={FilterListIcon}
                                onClose={handleCloseGroupSelect}
                                input={<OutlinedInput label="Filtrar por grupo"
                                    color='primary'
                                    sx={{
                                        color: '#3C4043', //cor do texto dentro do input
                                        '& .MuiOutlinedInput-notchedOutline': {
                                            borderColor: selectedGroupOptions.length > 0 ? '#c9c9c9' : 'transparent', // Cor da borda
                                            borderRadius: '6px',
                                            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                        },
                                        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9',

                                        },
                                        '.MuiSelect-iconOpen': {
                                            transform: 'none !important',
                                        },
                                        '&:hover .MuiOutlinedInput-notchedOutline': {
                                            borderColor: '#c9c9c9', // Cor da borda ao passar o mouse
                                        },

                                        borderRadius: '6px',
                                        border: 'none'


                                    }}

                                />}
                                renderValue={(selectedGroups) => {
                                    return selectedGroups
                                        .map(group => (selectGroupOptions.find((option: any) => option.value == group) as any)?.label as string ?? '')
                                        .join(', ')
                                }}
                            >
                                {selectGroupOptions.map((group: any) => (
                                    <MenuItem key={group.label} value={group.value}>
                                        <Checkbox checked={selectedGroupOptions.indexOf(group.value) > -1} />
                                        <ListItemText primary={group.label} />
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl> */}


                        {/* <StyledAsyncSelectFilter
                            // value={groupId}
                            // placeholder='Filtrar por grupo'
                            // name="group_id"
                            // defaultOptions
                            // isClearable
                            // components={{ DropdownIndicator }}
                            // loadOptions={debounceOptionsGroup}
                            // styles={colourStylesNormalSelect}
                            // noOptionsMessage={() => 'Não há dados de agrupamentos'}
                            // onChange={(e: any) => {
                            //     searchByGroup(e, 1)
                            // }}

                        /> */}

                    </ContainerFilters>


                </ContainerSelectFilters>



                {loading ? (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ minHeight: '300px' }}
                    >
                        <Spinner style={{ color: '#c9c9c9' }} />
                    </div>
                ) : (

                    <ContainerDashboard>
                        <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBodyOverflow style={{ background: '#FFF', color: '#3C4043', overflowX:'auto', overflowY: 'hidden' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Evolução do IFOZ durante o período</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <div style={{ overflow: 'auto', minWidth: '950px', overflowY:'hidden' }}>
                                            <DashboardChart positionLine='7%' lineSize='335px' height={400} data={pageData.ifozHistoric} typeKey={1} />
                                        </div>
                                    </Card.Body>
                                </StyledCardBodyOverflow>
                            </Col>
                        </Row>




                        <div style={{ padding: '2em 0 1em 0',  width: '100%' }}>
                            {loadingIndicators ? (
                                // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                            <div
                                className="d-flex align-items-center justify-content-center w-100"
                                style={{ minHeight: '300px',  width: '100%'}}
                            >
                                

                                <Row style={{width:'100%'}}>
                                    <Col md='12' style={{padding: '0'}}>
                                        <StyledCardBody style={{ background: '#FFF', color: '#3C4043', width: '100%' }}>

                                            <Card.Body style={{width: '100%'}}>
                                                <div className="d-flex align-items-center justify-content-center" style={{ minHeight: '300px', minWidth: '100%'}}>
                                                    <Spinner style={{ color: '#c9c9c9' }} />
                                                </div>
                                            </Card.Body>
                                        </StyledCardBody>
                                    </Col>
                                </Row>                                    

                            </div>
                            ) : (
                           
                                <TableComponent ifozHistoric={pageData.ifozHistoric} ipaHistoric={pageData.ipaHistoric} iepHistoric={pageData.iepHistoric} ifczHistoric={pageData.ifczHistoric} ></TableComponent>
                            )}                            
                        </div>




                        {/* <Row className='pt-4'>
                            <StyledCardIndicators>
                                <AttentionPoints title='Emoções positivas' date={dateValue} data={pageData.points} />

                                <AttentionPoints title='Engajamento' date={dateValue} data={pageData.points} />

                                <AttentionPoints title='Relacionamento' date={dateValue} data={pageData.points} />

                                <AttentionPoints title='Realização' date={dateValue} data={pageData.points} />

                                <AttentionPoints title='Significado' date={dateValue} data={pageData.points} />

                                <AttentionPoints title='vitalidade' date={dateValue} data={pageData.points} />
                            </StyledCardIndicators>
                        </Row> */}



                        {/* <Row className='pt-2'>
                            <Col>
                                <AttentionPoints date={dateValue} data={pageData.points} />
                            </Col>

                        </Row> */}



                        <Row className='pb-2 pt-2 '>
                            <Col md='6' >
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Emoções Positivas</h5>
                                    </StyledCardHeader>
                                    <Card.Body>
                                        {loadingEpHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.epHistoric} typeKey={2} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>

                            <Col md='6'  >
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Engajamento</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        {loadingEngHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.engHistoric} typeKey={3} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>

                            </Col>


                            <Col md='6'  >
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Relacionamento</h5>
                                    </StyledCardHeader>
                                    <Card.Body>
                                        {loadingRelHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.relHistoric} typeKey={4} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>

                            </Col>

                            <Col md='6'  >
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Significado</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        {loadingSigHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.sigHistoric} typeKey={5} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>

                            </Col>





                            <Col md='6'  >
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Realização</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        {loadingReaHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.reaHistoric} typeKey={6} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>

                            </Col>


                            <Col md='6'>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043' }}>
                                    <StyledCardHeader style={{ borderRadius: '.7em .7em 0 0' }}>
                                        <h5 className='pb-0'>Vitalidade</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        {loadingVitHistoric ? (
                                            // Os elementos abaixo serão exibidos caso a variável "loading" tenha um valor verdadeiro
                                            <div
                                                className="d-flex align-items-center justify-content-center"
                                                style={{ minHeight: '300px' }}
                                            >
                                                <Spinner style={{ color: '#c9c9c9' }} />
                                            </div>
                                        ) : (
                                            <DashboardChart positionLine='9%' lineSize='236px' height={300} data={pageData.vitHistoric} typeKey={7} />
                                        )}
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row>



                        {/* <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX: 'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por mês</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <ContainerOverflowCharts style={{ overflow: 'auto',  minWidth: '1000px' }}>
                                            <LineChartByMonth/>
                                            <MonthReportLineChart
                                                heightSize='295px'
                                                topPosition='14.5%'
                                                heightSizeResponsive='275px'
                                                topPositionResponsive='18.85%'
                                                chartId='eachMonthChart'
                                                generalEpData={generalData.generalEpDimension}
                                                generalEngData={generalData.generalEngDimension}
                                                generalRelData={generalData.generalRelDimension}
                                                generalSigData={generalData.generalSigDimension}
                                                generalReaData={generalData.generalReaDimension}
                                                generalVitData={generalData.generalVitDimension}
                                                monthSeries={[]} 
                                            />
                                        </ContainerOverflowCharts>
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row>  */}

                        {/* <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX:'auto'  }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por Gênero</h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <ContainerOverflowCharts style={{ overflow: 'auto',  minWidth: '1000px' }}>
                                            <LineChartGender/>
                                            <MonthReportLineChart
                                                heightSize='295px'
                                                topPosition='14.5%'
                                                heightSizeResponsive='275px'
                                                topPositionResponsive='18.85%'
                                                chartId='genderChart'
                                                generalEpData={generalData.generalEpDimension}
                                                generalEngData={generalData.generalEngDimension}
                                                generalRelData={generalData.generalRelDimension}
                                                generalSigData={generalData.generalSigDimension}
                                                generalReaData={generalData.generalReaDimension}
                                                generalVitData={generalData.generalVitDimension}
                                                genderSeries={genderSeries} 
                                            />
                                        </ContainerOverflowCharts>
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row>  */}


                        {/* <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX:'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por Faixa Etária </h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        
                                    <ContainerOverflowCharts>
                                        <LineChartAge/>
                                        <MonthReportLineChart
                                            heightSize='295px'
                                            topPosition='15%'
                                            heightSizeResponsive='275px'
                                            topPositionResponsive='18.85%'
                                            chartId='ageRangesChart'
                                            generalEpData={generalData.generalEpDimension}
                                            generalEngData={generalData.generalEngDimension}
                                            generalRelData={generalData.generalRelDimension}
                                            generalSigData={generalData.generalSigDimension}
                                            generalReaData={generalData.generalReaDimension}
                                            generalVitData={generalData.generalVitDimension}
                                            ageRangesSeries={ageRangesSeries} 
                                        />
                                    </ContainerOverflowCharts>
                                       
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row> */}


                        {/* <Row className='pb-2 pt-2 '>
                            <Col>
                                <StyledCardBody style={{ background: '#FFF', color: '#3C4043', overflowX:'auto' }}>

                                    <StyledCardHeader>
                                        <h5 className='pb-0'>Comparativo de cada questão - Por agrupamento </h5>
                                    </StyledCardHeader>

                                    <Card.Body>
                                        <ContainerOverflowCharts >
                                            <LineChartGroup/>
                                            <MonthReportLineChart
                                                heightSize='335px'
                                                topPosition='6%'
                                                heightSizeResponsive='275px'
                                                topPositionResponsive='18.85%'
                                                chartId='groupsChart'
                                                generalEpData={generalData.generalEpDimension}
                                                generalEngData={generalData.generalEngDimension}
                                                generalRelData={generalData.generalRelDimension}
                                                generalSigData={generalData.generalSigDimension}
                                                generalReaData={generalData.generalReaDimension}
                                                generalVitData={generalData.generalVitDimension}
                                                groupsSeries={groupsSeries} 
                                            />
                                        </ContainerOverflowCharts>
                                        
                                    </Card.Body>
                                </StyledCardBody>
                            </Col>
                        </Row> */}
                    </ContainerDashboard>
                )}
            </Container>
        </>

    );
};

export default Insights;