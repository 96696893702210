import { Card, CardBody, Col, Container, Row } from "react-bootstrap";
import { IoFilterOutline } from "react-icons/io5";
import { FiChevronRight, FiSearch, FiChevronLeft } from "react-icons/fi";
import {
  AddButton, CustomNav, InputWrapper, ListTable, SearchButton, StyledAsyncSelect, StyledAsyncSelectFilter, StyledAsyncSelectIndex, StyledSelect, StyledText, Subtitle, Title, CardManagerConfig,
  SaveButton, ContainerButton, Arrow, InputConfig, CardProfilePic, ContainerConfigGeneral, ContainerConfigUser, MenuButtonContainer, StyledTabContent, StyledTabPane, ContainerFilterButton, StyledCol, StyledColDetails, StyledColProfile,
  StyledInputSearchUser, ContainerConfigGroup, ContainerButtonGroup, StyledTh, StyledTd, StyledTr, StyledTdPhoto, StyledPhoto, StyledTitleSettings, ContainerStyledPhoto, StyledNameEmployee, StyledTdGroup, StyledSaveButton,
  ContainerEditGroup
} from "./styles";
import CustomCard from "../../components/customCard";
import ModalAddUser from "./modalUser";
import { useEffect, useRef, useState } from "react";
import { StyledCardTitle } from "../welcome/styles";
import CustomPagination from "../../components/customPagination";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Select, { components } from 'react-select';
import { NextButton, StyledTextArea } from "../../components/renderField/styles";
import ModalAddGroup from "./modalGroup";
import { getAdminsByCompany, getCompanyId, updateCompany } from "../../services/company/company";
import debounce from "debounce-promise";
import { useToast } from "../../context/Toast";
import { findUser, getUsersByCompany, getUsersByGroup, newUser } from "../../services/user/user";
import { UserRole } from "../../services/utils/enum";
import { addMember, createGroup, findGroup, getGroupByCompany, getGroupId } from "../../services/group/group";
import ModalAddMember from "./modalMember";
import Sidebar from "../../components/sideBar";
import { SidebarContainer, SidebarResponsive, Rectangle, CloseIcon } from "../../components/sideBar/styles";
import '../../components/sideBar/index.css'
import { FiAlignLeft, FiX } from "react-icons/fi"
import { StyleSheetManager } from 'styled-components';
import { NavLink as NavLinkResponsive } from 'react-router-dom';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import { useAuth } from "../../context/AuthContext";
import HeaderManager from "../../components/headerManager";
import SidebarResponsiveComp from "../../components/sideBar/siderbar-responsive";
import { AppBar, Avatar, Button, IconButton, ListItemIcon, Menu, MenuItem, Tooltip } from "@mui/material";
import { FilterList } from "@mui/icons-material";
import EditIcon from '@mui/icons-material/Edit';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from '@mui/icons-material/Delete';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import SearchIcon from '@mui/icons-material/Search';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import HideSourceIcon from '@mui/icons-material/HideSource';
import EmailIcon from '@mui/icons-material/Email';
import PersonOffIcon from '@mui/icons-material/PersonOff';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import FilterListIcon from '@mui/icons-material/FilterList';
import ClearIcon from '@mui/icons-material/Clear';



const Settings: React.FC = () => {
  const fileInputRef: any = useRef(null);
  const [showModal, setShowModal] = useState(false)
  const [showModalGroup, setShowModalGroup] = useState(false)
  const [modalAddMember, setModalAddMember] = useState({
    show: false,
    group_id: null
  })
  const [listData, setListData] = useState({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    data: []
  })

  const [listGroupData, setGroupListData] = useState({
    total_items: 0,
    total_pages: 0,
    current_page: 1,
    data: []
  })
  const [loading, setLoading] = useState(false)
  const [controlLoading, setControlLoading] = useState(false)
  const { showToast } = useToast()
  const [companyData, setCompanyData] = useState({
    id: "",
    name_company: "",
    cnpj: "",
    photo_logo: "",
    number_license_acquired: 1000,
    number_license_available: 0,
    admin_id: "",
    utc_offset: "",
    surveys_all_month: false,
  })

  const [groupId, setGroupId] = useState(null)

  const [activeTab, setactiveTab] = useState("1")

  const [term, setTerm] = useState('')

  const [groupTerm, setGroupTerm] = useState('')

  const { user } = useAuth()

  const colourStylesNormalSelect: any = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: undefined,
      boxShadow: 'none',
    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected || isFocused
            ? '#CCCCCF'
            : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected || isFocused
            ? '#3C4043'
            : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected || isFocused)
            ? '#191B35'
            : undefined,
        },
      };
    },
    singleValue: (styles: any) => ({
      ...styles,
      color: '#3C4043'
    }),
    valueContainer: (styles: any) => ({
      ...styles,
      color: '#3C4043',
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    // indicatorsContainer: (base: any) => ({
    //   ...base,


    // }),
    input: (base: any) => ({
      ...base,
      color: '#3C4043',
      width: '100%',

    }),
  };

  const colourStyles: any = {
    control: (styles: any) => ({
      ...styles,
      borderStyle: 'none',
      backgroundColor: undefined,
      boxShadow: 'none',

    }),
    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected || isFocused
            ? '#CCCCCF'
            : undefined,
        color: isDisabled
          ? '#ccc'
          : isSelected || isFocused
            ? 'white'
            : 'black',
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: !isDisabled && (isSelected || isFocused)
            ? '#CCCCCF'
            : undefined,
        },
      };
    },
    multiValue: (styles: any) => ({
      ...styles,
      backgroundColor: '#CCCCCF',
    }),
    multiValueLabel: (styles: any) => ({
      ...styles,
      color: '#3C4043',
    }),
    multiValueRemove: (styles: any, { data }: any) => ({
      ...styles,
      color: '#3C4043',
      ':hover': {
        backgroundColor: '#7e7e7e',
        color: '#3C4043',
      },
    }),
    indicatorSeparator: (base: any) => ({
      ...base,
      display: 'none',
    }),
    indicatorsContainer: (base: any) => ({
      ...base,
      display: 'none',

    }),
    input: (base: any) => ({
      ...base,
      color: '#3C4043',
    }),
  };

  const timeZones = [
    { label: "(GMT-03:00) São Paulo", value: "-03:00" },
    { label: "(GMT-05:00) Horário Padrão do Leste (EUA e Canadá)", value: "-05:00" },
    { label: "(GMT+01:00) Berlim, Paris, Madri", value: "+01:00" },
    { label: "(GMT+09:00) Tóquio, Seul", value: "+09:00" },
    { label: "(GMT+05:30) Índia (Nova Delhi, Mumbai)", value: "+05:30" },
    { label: "(GMT 00:00) Horário de Greenwich (Londres, Lisboa)", value: "00:00" }
  ];


  useEffect(() => {
    const loadData = async () => {
      try {

        setLoading(true)

        const company = user.user?.company_id
        if (!company) throw new Error("company_not_found")
        let dataForm = await getCompanyId(company)
        let newForm = { ...companyData }
        if (dataForm.Item) {
          newForm.id = dataForm.Item.id
          newForm.name_company = dataForm.Item.name_company
          newForm.cnpj = dataForm.Item.cnpj
          newForm.photo_logo = dataForm.Item.photo_logo
          newForm.number_license_acquired = dataForm.Item.number_license_acquired
          newForm.admin_id = dataForm.Item.admin_id ? dataForm.Item.admin_id : ''
          newForm.utc_offset = dataForm.Item.utc_offset
          newForm.surveys_all_month = dataForm.Item.surveys_all_month

        }

        setCompanyData(newForm)
      } catch (e: any) {
        setLoading(false)

        console.log(e)

      }
      setLoading(false)

    }
    loadData()
    searchUser(1)
    searchGroup(1)
  }, [])

  function toggle(tab: any) {
    if (activeTab != tab) {
      setactiveTab(tab)
    }
  }


  const onSubmitCompany = async () => {

    const dataToSend = { ...companyData }

    try {
      setControlLoading(true)
      await updateCompany(dataToSend)
      setControlLoading(false)
      showToast('Companhia atualizada', 'success')

    } catch (error) {
      setControlLoading(false)
      console.log(error)
      showToast('Erro ao atualizar companhia', 'error')
    }


  }

  const searchUser = async (page: number) => {
    setControlLoading(true)
    const company = user.user?.company_id
    if (!company) throw new Error("company_not_found")
    try {
      let filter = {
        company_id: company
      }

      let data = await getUsersByCompany(filter, page)
      for (let obj of data.data) {
        if (obj.group && obj.group.length > 0) {
          const groupId = obj.group[0];
          let groupInfo = null
          try {
            groupInfo = await getGroupId(groupId);
          } catch (error) {
            console.log(error)
          }

          obj.group = groupInfo;
        }
      }

      setControlLoading(false)
      setListData({
        total_items: data.total_items,
        total_pages: data.total_pages,
        current_page: parseInt(data.current_page),
        data: data.data
      })
    } catch (e) {
      setControlLoading(false)
      console.log(e)
    }

  }

  const searchGroup = async (page: number) => {
    setControlLoading(true)
    const company = user.user?.company_id
    if (!company) throw new Error("company_not_found")
    try {
      let filter = {
        company_id: company
      }

      let data = await getGroupByCompany(filter, page)
      setControlLoading(false)
      setGroupListData({
        total_items: data.total_items,
        total_pages: data.total_pages,
        current_page: parseInt(data.current_page),
        data: data.data
      })
    } catch (e) {
      setControlLoading(false)
      console.log(e)
    }
  }

  const searchUserByGroup = async (group_id: any, page: number) => {

    if (!group_id) {
      setGroupId(group_id)
      searchUser(1)
      return
    }
    setControlLoading(true)
    const company = user.user?.company_id
    if (!company) throw new Error("company_not_found")
    setGroupId(group_id)
    try {
      let filter = {
        company_id: company,
        group_id: group_id.value
      }


      let data = await getUsersByGroup(filter, page)
      for (let obj of data.data) {
        if (obj.group && obj.group.length > 0) {
          const groupId = obj.group[0];
          let groupInfo = null
          try {
            groupInfo = await getGroupId(groupId);
          } catch (error) {
            console.log(error)
          }

          obj.group = groupInfo;
        }
      }
      setControlLoading(false)
      setListData({
        total_items: data.total_items,
        total_pages: data.total_pages,
        current_page: parseInt(data.current_page),
        data: data.data
      })
    } catch (e) {
      setControlLoading(false)
      console.log(e)
    }
  }


  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    if (file && (file.type === "image/png" || file.type === "image/jpeg")) {
      const reader: any = new FileReader();
      reader.onload = (e: any) => {
        const img = document.createElement('img');
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx: any = canvas.getContext('2d');
          const max_width = 800;
          const scaleFactor = max_width / img.width;
          canvas.width = max_width;
          canvas.height = img.height * scaleFactor;

          ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

          const compressedBase64 = canvas.toDataURL('image/jpeg', 0.6);

          setCompanyData({ ...companyData, photo_logo: compressedBase64 });
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const loadAdminInputOptions = async (term: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let options: any = []
        const company = user.user?.company_id
        if (!company) throw new Error("company_not_found")
        let response: any = await getAdminsByCompany({ company_id: company })


        if (!response || response?.length <= 0) return resolve(options)

        response?.forEach((element: any) => {
          options.push({
            label: element?.first_name + ' ' + element?.last_name,
            value: element.id
          })
        })

        return resolve(options)
      } catch (e) {
        console.error(e)
        return reject([])
      }
    })
  }

  const debounceOptionsAdmins: any = debounce(loadAdminInputOptions, 600)

  const loadGroupInputOptions = async (term: string) => {
    return new Promise(async (resolve, reject) => {
      try {
        let options: any = []
        const company = user.user?.company_id
        if (!company) throw new Error("company_not_found")
        let response: any = await getGroupByCompany({ company_id: company }, 1)

        if (!response || response?.data?.length <= 0) return resolve(options)

        response?.data?.forEach((element: any) => {
          options.push({
            label: element?.name,
            value: element.id
          })
        })

        return resolve(options)
      } catch (e) {
        console.error(e)
        return reject([])
      }
    })
  }

  const debounceOptionsGroup: any = debounce(loadGroupInputOptions, 600)


  const handleSelectsChanges = (name: string, e: any): void => {
    setCompanyData({
      ...companyData,
      [name]: e
    })
  }


  const renderDate = (date: string) => {
    if (!date) return ''
    const dateObj = new Date(date);
    const options: Intl.DateTimeFormatOptions = {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric',
    };

    const formattedDate = dateObj.toLocaleDateString('pt-BR', options);
    return formattedDate;
  }
  const renderRole = (role_id: number) => {
    switch (role_id) {
      case UserRole.sAdmin:
        return "Consultor Zenbox";
      case UserRole.admin:
        return "Gestor de Felicidade";
      case UserRole.employee:
        return "Colaborador(a)";
      default:
        return "";
    }
  }

  const addItemToListData = async (newItem: any) => {
    try {
      await newUser(newItem)
      searchUser(1)
    } catch (e) {

      console.log(e)
      throw (e)
    }


  }

  const addItemGroupToListData = async (newItem: any) => {
    try {
      await createGroup(newItem)
      searchGroup(1)
    } catch (e) {

      console.log(e)
      throw (e)
    }
  }

  const addMemberToGroup = async (newItem: any) => {
    try {
      await addMember(newItem)
      searchGroup(1)
    } catch (e) {

      console.log(e)
      throw (e)
    }
  }



  const getInitials = (name: string) => {
    if (!name) return ''
    const firstInitial = name[0] ? name[0].toUpperCase() : '';
    const secondInitial = name[1] ? name[1].toUpperCase() : '';
    return firstInitial + secondInitial;
  }



  const findUserByInput = async () => {
    setControlLoading(true)
    const company = user.user?.company_id
    if (!company) throw new Error("company_not_found")
    try {
      let filter = {
        search: term,
        company_id: company
      }

      if (filter.search) {
        let data = await findUser(filter)
        for (let obj of data.data) {
          if (obj.group && obj.group.length > 0) {
            const groupId = obj.group[0];
            let groupInfo = null
            try {
              groupInfo = await getGroupId(groupId);
            } catch (error) {
              console.log(error)
            }

            obj.group = groupInfo;
          }
        }
        setListData({
          total_items: data.total_items,
          total_pages: data.total_pages,
          current_page: parseInt(data.current_page),
          data: data.data
        })
      } else await searchUser(1)
      setControlLoading(false)

    } catch (e) {
      setControlLoading(false)
      console.log(e)
    }
  }

  const findGroupByInput = async () => {
    setControlLoading(true)
    const company = user.user?.company_id
    if (!company) throw new Error("company_not_found")
    try {
      let filter = {
        search: groupTerm,
        company_id: company
      }

      if (filter.search) {
        let data = await findGroup(filter, 1)
        setGroupListData({
          total_items: data.total_items,
          total_pages: data.total_pages,
          current_page: parseInt(data.current_page),
          data: data.data
        })
      } else await searchGroup(1)
      setControlLoading(false)

    } catch (e) {
      setControlLoading(false)
      console.log(e)
    }
  }

  const [isOpen, setIsOpen] = useState(false);
  const toggleSidebar = () => setIsOpen(!isOpen);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);

  const open = Boolean(anchorEl);
  const openUser = Boolean(anchorElUser);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClickUsers = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };



  const iconPathsResponsive: any = {
    configuracao: require('../../assets/settings (1) 1.png'),
    sustentabilidade: require('../../assets/ic_twotone-speed.png'),
    calendario: require('../../assets/calendar (5) 1.png'),
    relatorio: require('../../assets/file-text (3) 1.png'),
    insight: require('../../assets/Insights.png'),
  };

  const ChipActive = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Tooltip title="Significa que o usuário já fez seu primeiro acesso e finalizou o seu cadastro. Usuários ativos não necessariamente responderam uma reflexão ainda, porém já podem responder durante o periodo da reflexão e irão receber lembretes todo mês para participar." enterTouchDelay={0} leaveTouchDelay={2000} arrow>
            <div style={{ background: '#BBF8BD', color: '#338D31', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.5em .8em', borderRadius: '2em', gap: '.4em' }}>
              <RadioButtonCheckedIcon sx={{ fontSize: 15 }} />
              <p style={{ margin: '0', fontSize: '12px' }}>Ativo</p>
            </div>
          </Tooltip>
          


        </div>
      </div>
    );
  }

  const ChipInactive = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Tooltip title="Significa que o usuário não irá participar das reflexões. Usuários inativos não são contabilizados nos indicadores de participação (Aderência, Engajamento e Fidelização), também não podem acessar sua conta nem receber lembretes durante o periodo de medição." enterTouchDelay={0} leaveTouchDelay={2000} arrow>
            <div style={{ background: '#DDD', color: '#878787', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.5em .8em', borderRadius: '2em', gap: '.4em' }}>
              <HideSourceIcon sx={{ fontSize: 15 }} />
              <p style={{ margin: '0', fontSize: '12px' }}>Inativo</p>
            </div>
          </Tooltip>
          


        </div>
      </div>
    );
  }

  const ChipInvalid = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Tooltip title="Significa que o usuário teve sua conta inativada pelo time de suporte técnico da Zenbox para manutenção. Usuários inativos não são contabilizados nos indicadores de participação (Aderência, Engajamento e Fidelização), também não podem acessar sua conta nem receber lembretes durante o periodo de medição." enterTouchDelay={0} leaveTouchDelay={2000} arrow>
            <div style={{ background: '#DDD', color: '#878787', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.5em .8em', borderRadius: '2em', gap: '.4em' }}>
              <HideSourceIcon sx={{ fontSize: 15 }} />
              <p style={{ margin: '0', fontSize: '12px', whiteSpace: "nowrap" }}>Inativado pelo suporte</p>
            </div>
          </Tooltip>
          


        </div>
      </div>
    );
  }


  const ChipInvited = () => {
    return (
      <div>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

          <Tooltip title="Significa que o usuário recebeu um convite em seu e-mail para ativar sua conta. Usuários convidados ainda não fizeram seu cadastro, porém são contabilizados nos indicadores de participação (Aderência, Engajamento e Fidelização) e recebem lembretes durante o periodo de medição." enterTouchDelay={0} leaveTouchDelay={2000} arrow>
            <div style={{ background: '#F8E7BB', color: '#8D7931', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '.5em .8em', borderRadius: '2em', gap: '.4em' }}>
              <EmailIcon sx={{ fontSize: 15 }} />
              <p style={{ margin: '0', fontSize: '12px' }}>Convidado</p>
            </div>
          </Tooltip>
          


        </div>
      </div>
    );
  }



  const DropdownIndicator = (props: any) => {
    return (
      <components.DropdownIndicator {...props}>
        <FilterList /> {/* Use o ícone de filtro aqui */}
      </components.DropdownIndicator>
    );
  };

  return (

    <>


      <SidebarResponsiveComp title='Configurações' />






      <HeaderManager title='Configurações' />


      <>

        <CustomNav className="mt-1"
          tabs

        ><NavItem>
            <NavLink
              className={activeTab === "1" ? "activeNav" : ""}
              style={{
                cursor:
                  "pointer",
              }}

              onClick={() => {
                toggle("1")
              }}
            >
              Geral
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "activeNav" : ""}
              style={{
                cursor:
                  "pointer",
              }}

              onClick={() => {
                toggle("2")
              }}
            >
              Usuários
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "activeNav" : ""}
              style={{
                cursor:
                  "pointer",
              }}

              onClick={() => {
                toggle("3")
              }}
            >
              Grupos
            </NavLink>
          </NavItem>
        </CustomNav>
      </>

      <Container fluid className="py-4 mt-2">

        <TabContent activeTab={activeTab}>

          <TabPane style={{ background: 'transparent' }} tabId="1">

            <ContainerConfigGeneral>

              <StyledCol>

                <CardManagerConfig style={{ background: '#FFF', color: '#3C4043' }}>

                  <Card.Body style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    padding: '2px'
                  }} className="p-2">
                    <div>
                      <Title className="m-0">Detalhes da empresa</Title>
                      <Subtitle className="m-0">Adicione os detalhes da organização</Subtitle>
                    </div>
                    <Arrow size={20} />
                  </Card.Body>

                </CardManagerConfig>

              </StyledCol>

              <StyledColDetails>

                <CardManagerConfig style={{ background: '#FFF', color: '#3C4043' }}>

                  <Card.Body className="p-2">
                    <Title>Detalhes sobre a sua organização</Title>
                    <Subtitle className="m-0 mb-1">Nome da organização</Subtitle>
                    <InputConfig style={{ padding: '4px', borderRadius: '6px' }}
                      id={`name_company`}
                      type='text'
                      value={companyData?.name_company}
                      rows="1"
                      onChange={(event: any) => {
                        handleSelectsChanges('name_company', event.target.value)
                      }}

                    />

                    <Subtitle className="mt-4 mb-1">Gestores de Felicidade</Subtitle>
                    <StyledAsyncSelectIndex
                      isMulti
                      value={companyData?.admin_id}
                      placeholder=''
                      name="admin_id"
                      defaultOptions={true}
                      loadOptions={debounceOptionsAdmins}
                      styles={colourStyles}
                      noOptionsMessage={() => 'Sem opção'}
                      onChange={(event: any) => {
                        handleSelectsChanges('admin_id', event)
                      }}
                    />

                    <Subtitle className="mt-4 mb-1">Fuso Horário</Subtitle>
                    <InputConfig style={{ padding: '4px', borderRadius: '6px' }}
                      id={`utc_offset`}
                      type='select'
                      placeholder="(GMT-03:00) São Paulo"
                      value={companyData?.utc_offset || ""}
                      onChange={(event: any) => {
                        handleSelectsChanges('utc_offset', event.target.value)
                      }}
                    >
                      <option value={''}>
                        Selecione
                      </option>
                      {timeZones.map((timezone, index) => (
                        <option key={index} value={timezone.value}>
                          {timezone.label}
                        </option>
                      ))}
                    </InputConfig>
                    <ContainerButton className="mt-4 ">
                      <StyledSaveButton disabled={controlLoading} onClick={onSubmitCompany} style={{ padding: '10px 25px' }}>Salvar</StyledSaveButton>
                    </ContainerButton>

                  </Card.Body>

                </CardManagerConfig>

              </StyledColDetails>

              <StyledColProfile>

                <CardProfilePic style={{ color: '#ffff' }}>
                  <Card.Body className="m-2">
                    <div style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      flexDirection: 'column',
                      width: '100%',
                    }}>

                      <div style={{
                        width: '150px',
                        height: '150px',
                        backgroundColor: 'white',
                        borderRadius: '50%',
                        marginBottom: '25px',
                        backgroundImage: `url(${companyData.photo_logo})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        border: '2px solid #D9D9D9'
                      }}>

                        <input
                          type="file"
                          style={{ display: 'none' }}
                          onChange={handleImageChange}
                          accept="image/png, image/jpeg"
                          ref={fileInputRef}
                        />

                      </div>

                      <SaveButton disabled={controlLoading} onClick={() => fileInputRef.current && fileInputRef.current.click()} style={{}}>Alterar</SaveButton>
                    </div>
                  </Card.Body>

                </CardProfilePic>

              </StyledColProfile>


            </ContainerConfigGeneral>



          </TabPane>
        </TabContent>
        <StyledTabContent activeTab={activeTab}>
          <StyledTabPane tabId="2">
            <ContainerConfigUser>

              <InputWrapper>
                <StyledInputSearchUser placeholder="Procurar usuário" onChange={(e) => setTerm(e.target.value)} />
                <SearchButton>
                  <FiSearch style={{ color: '  #878B8E' }} onClick={findUserByInput} size={20} />
                </SearchButton>
              </InputWrapper>


              <ContainerFilterButton>


                <AddButton onClick={() => setShowModal(true)}>+   Adicionar usuário</AddButton>


                <StyledAsyncSelectFilter
                  value={groupId}
                  placeholder='Filtrar por grupo'
                  name="group_id"
                  defaultOptions
                  isClearable
                  loadOptions={debounceOptionsGroup}
                  components={{ DropdownIndicator }}
                  styles={colourStylesNormalSelect}
                  noOptionsMessage={() => 'Sem opção'}
                  onChange={(e: any) => {
                    searchUserByGroup(e, 1)
                  }}

                />



              </ContainerFilterButton>



            </ContainerConfigUser>


            <Row className="mt-4">

              {listData.data.length > 0 ?
                <div style={{ width: '100%', maxWidth: '100%', overflowX: 'auto' }}>
                  <ListTable>
                    <thead>
                      <tr>
                        <th style={{ width: '20%' }} >Mostrando 1-{listData.data.length} de {listData.data.length} usuários</th>
                        <StyledTh scope="col">Cargo</StyledTh>
                        <StyledTh scope="col">E-mail</StyledTh>
                        <StyledTh scope="col">Grupo</StyledTh>
                        <StyledTh style={{ width: '30%' }} scope="col">Data de Ingresso</StyledTh>
                        <StyledTh scope="col">Situação</StyledTh>
                        <StyledTh></StyledTh>
                      </tr>
                    </thead>
                    <Tbody>
                      {listData.data.map((item: any, index) => (
                        <StyledTr key={index} >
                          <StyledTdPhoto style={{ display: 'flex', alignItems: 'center', height: '100%' }} >
                            <ContainerStyledPhoto>
                              {/* imagem de perfil do colocaborador */}
                              <Avatar sx={{ background: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)', color: '#3C4043', width: 35, height: 35, fontSize: '14px' }}>
                                {getInitials(item?.first_name)}
                              </Avatar>

                              <StyledNameEmployee>
                                {item?.first_name && item?.last_name ? item.first_name + ' ' + item.last_name : ' - - '}
                              </StyledNameEmployee>

                            </ContainerStyledPhoto>


                          </StyledTdPhoto>
                          <StyledTd style={{ height: '100%' }} data-cell='Cargo'>{renderRole(item?.role)}</StyledTd>
                          <StyledTd data-cell='E-mail'>{item?.email}</StyledTd>
                          <StyledTd data-cell='Grupo'>{item?.group?.name}</StyledTd>
                          <StyledTd data-cell='Data de Ingresso'>{renderDate(item?.created_at)}</StyledTd>

                          <StyledTd style={{ width: '100%' }} data-cell='Status'>
                            {item?.status == 1 ? <ChipActive />
                              : item?.status == 2 ? <ChipInvited />
                                : item?.status == 3 || item?.status == 4 ? <ChipInactive />
                                  : item?.status == 5 ? <ChipInvalid/> : null 
                            }
                          </StyledTd>

                          {/* Linha do icone de Mais opções */}
                          {/* <StyledTd >
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                              <Tooltip title='Mais opções' enterTouchDelay={0} leaveTouchDelay={2000} arrow>
                                <IconButton onClick={handleClickUsers}>
                                  <MoreVertIcon />
                                </IconButton>
                              </Tooltip>

                              <Menu
                                sx={{
                                  '& .MuiPaper-root': {
                                    backgroundColor: '#FFF',
                                    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.05)',
                                    borderRadius: '8px',

                                  },
                                }}
                                disableScrollLock={true}
                                anchorEl={anchorElUser}
                                open={openUser}
                                onClose={() => setAnchorElUser(null)}

                                anchorOrigin={{
                                  vertical: 'bottom',
                                  horizontal: 'right',

                                }}
                                transformOrigin={{
                                  vertical: 'top',
                                  horizontal: 'right',
                                }}>
                                <MenuItem>
                                  <ListItemIcon>
                                    <EditIcon fontSize="small" />
                                  </ListItemIcon>
                                  Editar Informações
                                </MenuItem>

                                <MenuItem onClick={(event: any) => {
                                  console.log(item)
                                }}>
                                  {
                                    item.status > 2 ? (
                                      <>
                                        <ListItemIcon>
                                          <SettingsBackupRestoreIcon fontSize="small" />
                                        </ListItemIcon>
                                        Ativar usuário
                                      </>
                                    ) : (
                                      <>
                                        <ListItemIcon>
                                          <PersonOffIcon fontSize="small" />
                                        </ListItemIcon>
                                        Inativar usuário
                                      </>
                                    )
                                  }
                                </MenuItem>
                              </Menu>

                            </div>
                          </StyledTd> */}
                          {/* Linha do icone de Mais opções */}

                        </StyledTr>
                      ))}
                    </Tbody>
                  </ListTable>
                  <CustomPagination
                    data={listData}
                    handlePageChange={e => {
                      searchUser(e)
                    }}
                  />
                </div>
                : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '5em' }}>
                    <StyledCardTitle style={{ color: ' #3C4043' }}>Nenhum usuário encontrado</StyledCardTitle>
                  </div>
                )
              }
            </Row>
          </StyledTabPane>
        </StyledTabContent>


        <StyledTabContent activeTab={activeTab}>
          <TabPane style={{ background: 'transparent' }} tabId="3">
            <ContainerConfigGroup>

              <InputWrapper>
                <StyledInputSearchUser placeholder="Procurar grupo" onChange={(e) => setGroupTerm(e.target.value)} />
                <SearchButton>
                  <FiSearch style={{ color: '  #878B8E' }} onClick={findGroupByInput} size={20} />
                </SearchButton>
              </InputWrapper>



              <ContainerButtonGroup>
                <AddButton onClick={() => setShowModalGroup(true)}>+ Adicionar agrupamento</AddButton>
              </ContainerButtonGroup>








            </ContainerConfigGroup>


            <Row className="mt-4">

              {listGroupData.data.length > 0 ?

                <div style={{ overflowX: 'auto' }}>
                  <ListTable >
                    <thead >
                      <tr >
                        <th scope='col'>Mostrando 1-{listGroupData.data.length} de {listGroupData.data.length} grupos</th>
                        <StyledTh scope='col'>Data de Criação</StyledTh>
                        <StyledTh scope='col'>Qtde. Membros</StyledTh>
                        <StyledTh scope='col'></StyledTh>
                      </tr>
                    </thead>
                    <Tbody>
                      {listGroupData.data.map((item: any, index) => (
                        <StyledTr key={index}>
                          <td>
                            <ContainerStyledPhoto>
                              <Avatar sx={{ background: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)', color: '#3C4043', width: 35, height: 35, fontSize: '14px' }}>
                                {getInitials(item?.name)}
                              </Avatar>

                              <StyledNameEmployee>
                                {item?.name}
                              </StyledNameEmployee>

                            </ContainerStyledPhoto>

                          </td>
                          <StyledTdGroup data-cell='Data de criação'>{renderDate(item?.created_at)}</StyledTdGroup>
                          <StyledTdGroup data-cell='Qtde De Membros'>{item?.quantity_members}</StyledTdGroup>
                          <StyledTdGroup>
                            <ContainerEditGroup>
                              <Button variant="contained"
                                sx={{
                                  boxShadow: 'none',
                                  backgroundColor: '#BBE2F8',
                                  color: '#4F89BE',
                                  fontWeight: '500',
                                  display: 'flex',
                                  textTransform: 'none',
                                  fontSize: '1em',
                                  padding: '.5em 1.5em',
                                  borderRadius: '2em',
                                }}
                                startIcon={<EditIcon sx={{ fontSize: 20 }} />}
                                onClick={() => setModalAddMember({
                                  show: true,
                                  group_id: item.id
                                })} >
                                Editar membros
                              </Button>
                              <div>
                                <Tooltip title='Mais opções' enterTouchDelay={0} leaveTouchDelay={2000} arrow>
                                  <IconButton onClick={handleClick}>
                                    <MoreVertIcon />
                                  </IconButton>
                                </Tooltip>

                                <Menu
                                  sx={{
                                    '& .MuiPaper-root': {
                                      backgroundColor: '#FFF',
                                      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.09)',
                                      borderRadius: '8px',

                                    },
                                  }}
                                  disableScrollLock={true}
                                  anchorEl={anchorEl}
                                  open={open}
                                  onClose={handleClose}

                                  anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                  }}
                                  transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                  }}                                 >
                                  <MenuItem sx={{ color: 'rgba(190, 79, 79, 1)' }} onClick={handleClose}>
                                    <ListItemIcon>
                                      <DeleteIcon fontSize="small" sx={{ color: 'rgba(190, 79, 79, 1)' }} />
                                    </ListItemIcon>
                                    Excluir grupo
                                  </MenuItem>
                                </Menu>
                              </div>
                            </ContainerEditGroup>
                          </StyledTdGroup>
                        </StyledTr>
                      ))}
                    </Tbody>
                  </ListTable>
                  <CustomPagination
                    data={listGroupData}
                    handlePageChange={e => {
                      searchGroup(e)
                    }}
                  />
                </div>
                : (
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', paddingTop: '5em' }}>
                    <StyledCardTitle style={{ color: ' #3C4043' }}>Nenhum grupo encontrado</StyledCardTitle>
                  </div>
                )
              }
            </Row>
          </TabPane>
        </StyledTabContent>



      </Container>
      <ModalAddUser show={showModal} onClose={() => {
        setShowModal(false)
      }}
        addItem={addItemToListData} />

      <ModalAddGroup show={showModalGroup} onClose={() => {
        setShowModalGroup(false)
      }}
        addItem={addItemGroupToListData} />

      <ModalAddMember show={modalAddMember.show} group_id={modalAddMember.group_id} onClose={() => {
        setModalAddMember({
          show: false,
          group_id: null,
        })
      }}
        addItem={addMemberToGroup} />

    </>








  );
};

export default Settings;
