import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spinner } from 'react-bootstrap';
import { CardEmployee, CardInicio, StartButton, StyledCardSubtitle, StyledCardText, StyledCardTitle, StyledCardTitleEmployee } from '../../pages/welcome/styles';
import { useQuestions } from '../../context/QuestionContext';
import questions from '../../data/questions.json'
import RenderField, { IQuestion } from '../renderField';
import { useAuth } from '../../context/AuthContext';
import { initAnswers } from '../../services/question/question';
import { useToast } from '../../context/Toast';
import { BackButton } from '../renderField/styles';
import { useNavigate } from 'react-router-dom';
import { getUserByEmail } from '../../services/user/user';




const QuestionCard: React.FC = () => {
    const [flagStatus, setFlagStatus] = useState(false)
    const [loading, setLoading] = useState(false)
    const { showToast } = useToast()
    const {
        currentQuestionIndex,
        setCurrentQuestionIndex,
        answerId,
        saveDataAnswerOnStorage
    } = useQuestions();
    const navigate = useNavigate()

    const initQuestion = async () => {
        try {
            const storedUserString = localStorage.getItem('zenbox.identity');
            const storedUser = storedUserString ? JSON.parse(storedUserString) : null;
            if (!storedUser || (storedUser && Object.keys(storedUser.user).length === 0)) {
                throw('user_storage_not_found');
            }
            
            setLoading(true)
            let userDb = null
            try {
                userDb = await getUserByEmail(storedUser.user?.email)
            } catch (error) {
                console.log(error)
            }
            const userId = storedUser.user?.id || userDb?.id;
            const userStatus = userDb?.status;
            if (userStatus != 1) throw ('user_not_active')
            const dataToSend = {
                answer: {},
                user_id: userId
            }
            const result = await initAnswers(dataToSend)
            saveDataAnswerOnStorage(result.id)
            setCurrentQuestionIndex(0);
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setCurrentQuestionIndex(null)
            if (error == 'user_not_active'){
                setFlagStatus(true)
            } else {
                showToast('Ops! Algo não correu bem :(\n\nNosso time de engenheiros foi notificado e iremos corrigir essa questão o quanto antes. Para falar com nosso suporte, utilize o botão "Ajuda" na parte inferior da tela. ', 'error', 30000)
                throw error
            }
        }
    }


    useEffect(() => {
        if (!localStorage.getItem('zenbox.question')) {
            initQuestion()
        }else{
            setCurrentQuestionIndex(0);
        }



    }, [])



    return (
        <>
            {loading ? (
                <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ minHeight: '300px' }}
                >
                    <Spinner style={{ color: '#c9c9c9' }} />
                </div>
            ) : (
                <CardEmployee>
                    <Card.Body>
                        {currentQuestionIndex != null ? (
                            <div>
                                <RenderField entity={questions[currentQuestionIndex]} />
                            </div>
                        ) : (
                            flagStatus ? (
                                <>

                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ minHeight: '300px' }}
                                    >
                                        <StyledCardTitleEmployee>Para responder a reflexão você precisa finalizar seu cadastro</StyledCardTitleEmployee>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                        <BackButton onClick={() => navigate('/register')} >Finalizar Cadastro</BackButton>

                                    </div>
                                </>
                            ) : (
                                <>

                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ minHeight: '300px' }}
                                    >
                                        <StyledCardTitleEmployee>Ops! Algo não correu bem e por isso não conseguimos iniciar a reflexão... Para mais informações entre em contato com nosso suporte.</StyledCardTitleEmployee>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', marginTop: 20 }}>
                                        <BackButton onClick={() => navigate('/welcome')} >Voltar</BackButton>

                                    </div>
                                </>
                            )



                        )}
                    </Card.Body>
                </CardEmployee>
            )}
        </>
    );

};

export default QuestionCard;
