import React from 'react';
import { BoldText, HeaderContainer, LogoutButton, NormalText, ContainerMessage, ContainerMessageTextBold, ContainerMessageText, ContainerUserInformation, 
PersonName, CompanyName, MainTextSecondary,LogoutResponsive, LogoutDesktop
} from './styles';
import { useAuth } from '../../context/AuthContext';
import { useQuestions } from '../../context/QuestionContext';
import styled, {css} from 'styled-components';
import { StyleSheetManager } from 'styled-components';
import { Avatar, IconButton, Tooltip } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';




const Header: React.FC = () => {
  const { SignOut, user } = useAuth()
  const { removeAnswer, answerId } = useQuestions()

  const getInitials = (name: string, surname: string) => {
    if (!name) return ''
    const firstInitial = name[0] ? name[0].toUpperCase() : '';
    const secondInitial = surname[0] ? surname[0].toUpperCase() : '';
    return firstInitial + secondInitial;
  }


  return (

    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'hasBackground'}>
      <HeaderContainer hasBackground={user?.user?.id}>
      {user?.user?.id && (
        <LogoutDesktop style={{width: '100%'}}>
           <LogoutButton onClick={() => {
              if (answerId) removeAnswer();
              SignOut();
            }}>
            <LogoutIcon/>
            Trocar de conta
          </LogoutButton>
        </LogoutDesktop>
        
      )}
        <ContainerMessage>
          <span style={{textAlign: 'center'}}><strong>🔒 Suas respostas são anônimas.</strong> </span>
          <MainTextSecondary style={{ opacity: 0.7, textAlign: 'center' }}>Sinta-se a vontade para compartilhar sua percepção</MainTextSecondary>
        </ContainerMessage>
        {user?.user?.id && (
          <>
            <ContainerUserInformation >

            <LogoutResponsive>
              <LogoutButton onClick={() => {
                if (answerId) removeAnswer();
                SignOut();
                }}>
                <LogoutIcon/>
                Trocar de conta
              </LogoutButton>
            </LogoutResponsive>  
           

              <div style={{display: 'flex', gap: '.5em'}}>
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}>
                    <Tooltip enterTouchDelay={0} leaveTouchDelay={2000} arrow title={`${user?.user?.first_name} ${user?.user?.last_name }` } >
                      <PersonName style={{color: '#FFF', textAlign: 'right' , fontWeight: '700' }}>{user?.user?.first_name} {user?.user?.last_name}</PersonName>
                    </Tooltip>
                  
                    <CompanyName style={{color: '#FFF', textAlign: 'right' }}>{user?.user?.company_name}</CompanyName>
                  </div>


                <div>
                  <IconButton sx={{padding: '4px'}} size="small">
                
                    <Avatar sx={{background: 'linear-gradient(318deg, #00B2B5 16.44%, #96FFD2 85.22%)', color:'#3C4043', fontSize: '16px'}}>
                      {getInitials(user.user?.first_name, user.user?.last_name)}
                    </Avatar>
              
                  </IconButton>
                </div>
              </div>
              
              

            </ContainerUserInformation>

            
          </>
        )}
      </HeaderContainer>
    </StyleSheetManager>
  );
};


export default Header;
