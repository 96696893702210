import { DimensionFormattedName, DimensionID } from "../../enums/dimension";
import { AllDimensionsIfozElements, DimensionIfozElement, FormattedDimensionResults, FormattedQuestionResults } from "../../interfaces/dimension.interface";
import { getAllDimensionsIFOZByParameters, getIFOZDimensionByParameters } from "./happinessReport";
import surveyConfigQuestions from '../../data/questions.json'



const getAllDimensionsIFOZ = async (companyId: string, month?: string, year?: string, filters?: any): Promise<FormattedDimensionResults[]> => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString();
    const currentMonth = (currentDate.getMonth() + 1).toString().padStart(2, '0');

    const yearToUse = year || currentYear;
    const monthToUse = month || currentMonth;
    

    let dimensionsIfozList: FormattedDimensionResults[] = [];
    let dimensionsIdList: DimensionID[] = Object.values(DimensionID).filter(value => typeof value === 'number') as DimensionID[];
    let allDimensionsIfozElements: AllDimensionsIfozElements = await getAllDimensionsIFOZByParameters(companyId, monthToUse, yearToUse, filters);
    
    // Aqui consultamos os indicadores de cada dimensão
    for (let dimensionID of dimensionsIdList) {
        // let dimensionIfozElement: DimensionIfozElement = await getIFOZDimensionByParameters(companyId, dimensionID, monthToUse, yearToUse, filters);

        let dimensionIfozElement: DimensionIfozElement = allDimensionsIfozElements[dimensionID];

        let formattedDimensionResults: FormattedDimensionResults;

        // Configurando o molde dos resultados da dimensão com base na config das questões do formulário, no questions.json
        let formattedQuestionResults: FormattedQuestionResults[] = surveyConfigQuestions
                .filter(surveyConfigQuestion => surveyConfigQuestion.dimension === DimensionFormattedName[dimensionID].toUpperCase())
                .map((question) => ({
                    id: question.id,
                    question: question.question,
                    value: null,
                }));

        let dimensionTotalIfoz = null;

        if(allDimensionsIfozElements.error || dimensionIfozElement.error) {
            formattedDimensionResults = {dimensionId: dimensionID, questionsResults: formattedQuestionResults, totalIfoz: null}
        
        } else {
            formattedQuestionResults.forEach((question: FormattedQuestionResults, index: number) => {
                // formattedQuestionResults[index].value == dimensionIfozElement.questions.find(item => item.questionId == question.id)?.ifoz
                const foundQuestion = dimensionIfozElement.questions.find(item => item.questionId === question.id);
                if (foundQuestion) {
                    formattedQuestionResults[index].value = foundQuestion.ifoz;
                }    
            });

            dimensionTotalIfoz = dimensionIfozElement.dimensionTotal.ifoz;

            formattedDimensionResults = {dimensionId: dimensionID, questionsResults: formattedQuestionResults, totalIfoz: dimensionTotalIfoz}
        }

        dimensionsIfozList.push(formattedDimensionResults);            
    }

    return dimensionsIfozList;
}

const getQuestionIFOZArrayFromDimension = (dimension: any): number[] => {
    return dimension.filter((item: any) => item.hasOwnProperty('value')).map((item: any) => {
      return (item.value ? item.value.toFixed(1) : null)
    });
  }

const getDataFromFilteredSeries = (filteredSerie: any) => {
    let epValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.POSITIVE_EMOTIONS - 1].questionsResults)
    let enValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.ENGAGEMENT - 1].questionsResults)
    let relValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.RELATIONSHIP - 1].questionsResults)
    let sigValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.MEANING - 1].questionsResults)
    let reaValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.ACCOMPLISHMENT - 1].questionsResults)
    let vitValues: number[] = getQuestionIFOZArrayFromDimension(filteredSerie.data[DimensionID.VITALITY - 1].questionsResults)


    let dimensionValues: number[] = [...epValues, ...enValues, ...relValues, ...sigValues, ...reaValues, ...vitValues];

    return dimensionValues;
}

const getHumanSustainabilityTagFromIndicator = (indicator: number | null | undefined) => {
    if (!indicator) {
        return "";
      } else if (indicator >= 80 && indicator <= 100) {
        return "Excelente";
      } else if (indicator >= 70 && indicator < 80) {
        return "Bom";
      } else if (indicator >= 60 && indicator < 70) {
        return "Razoável";
      } else if (indicator >= 40 && indicator < 60) {
        return "Em Risco";
      } else if (indicator >= 0 && indicator < 40) {
        return "Crítico";
      } else {
        return "Indicador inválido!";
    }
}

export { getAllDimensionsIFOZ, getDataFromFilteredSeries, getQuestionIFOZArrayFromDimension, getHumanSustainabilityTagFromIndicator }
